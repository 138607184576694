.hero-slider-content-left {
  padding-left: 25px;
}

.teacher-single-item h2 {
  font-weight: 700;
  color: #1b3f73;
  font-size: 48px;
  line-height: 72px;
}
.service-main-page {
  padding: 60px 0;
}
