.hero-main-wrap {
  padding: 50px 0;
  padding-top: 30px;
}

.hero-inner-wrap {
  width: 100%;
  position: relative;
  align-items: center;
  border-radius: 10px;
}

.hero-right-image img {
  width: 100%;
}

.hero-slider-content-left {
  padding-left: 25px;
}

.hero-left-image img {
  width: 100%;
  display: inline-block;
}

.hero-slider-content-left h2 span {
  color: red;
  display: inline-block;
  margin-right: 7px;
}

.hero-slider-content-left h2 {
  font-weight: 700;
  font-size: 40px;
}
.header-login-dropdown.dropdown button {
  border: none;
  background: transparent;
  padding: 0 0px;
}
.dropdown-toggle::after {
  display: none;
}
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: none;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem transparent;
}
.navbar__link.header-login-btn .dropdown-menu.show {
  display: block;
  top: 30px !important;
  text-align: center;
}
span.font-smaller {
  font-size: 13px;
}
a.dropdown-item {
  border: 1px solid #1b3f73;
  border-radius: 5px;
  margin: 10px 10px;
}
a.dropdown-item {
  border: 1px solid #1b3f73;
  border-radius: 5px;
  margin: 10px 10px;
  width: 180px;
}
button.hero-area.navbar__link.header-login-btn {
  margin: 0;
  margin-top: 30px;
}
.academic-right-single-item {
  display: flex;

  gap: 4vw;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 14px 0;
}
.academic-right-single-item > a {
  width: 44%;
  text-decoration: none;
  color: #8f8f8f;
  transition: 0.5s;
  font-weight: 500;
  display: block;
  font-size: 12px;
}
.academic-right-single-item > a:hover {
  color: #583bab;
  transition: 0.5s;
}
.hero-slider-content-right h3 {
  color: #583bab;
  font-weight: 700;
  margin-bottom: 15px;
}
.hero-left-image img {
  width: 100%;
  display: inline-block;
  /* padding-top: 10%; */
  border-radius: 10px;
}
.business-cases-area-title h3 {
  margin-bottom: 40px;
  font-weight: 700;
  color: #583bab;
  font-size: 36px;
  margin-top: 30px;
}
.hero-slider-content-right {
  background: #fff;
  padding: 15px 30px;
  padding-top: 24px;
  box-shadow: 0px 0px 10px 0px #dddddd57;
  border-radius: 14px;
}
.academic-right-single-item:last-child {
  border-bottom: none;
}
.hero-left-image {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.hero-content-left > * {
  color: #fff;
}

.hero-content-left {
  padding: 10px 5%;
  position: relative;
  z-index: 9;
}
.hero-content-left h2 {
  font-weight: 700;
  font-size: 48px;
  text-align: left;
  line-height: 68px;
}
.hero-content-left p {
  font-size: 24px;
  font-weight: 500;
  color: #ededed;
}
.hero-left-image:after {
  background: #00000095;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
}
