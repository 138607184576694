.feature-single-item {
  text-align: center;
}

.feature-single-item img {
  height: 45px;
  filter: grayscale(1);
  display: inline-block;
}
.feature-single-item img:hover {
  filter: grayscale(0);
}
.feature-items-wrap {
  position: relative;
}

.slick-slider.slider-wrap.slick-initialized {
  width: 80%;
  margin-left: auto;
}

.our-customer h6 {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #00000090;
  margin-bottom: 5px;
}

.our-customer h4 {
  font-size: 24px;
  font-weight: 700;
}
.our-customer {
  height: 100%;
  /* background: #fff; */
  position: absolute;
  z-index: 99;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  padding-left: 0;
}

@media only screen and (max-width: 770px) {
  .feature-single-item img {
    width: unset;
    /* height: unset; */
  }
  .footer-single-item-content.insights-image img {
    height: 50px;
  }

  .teacher-single-item.text-center img {
    height: 260px;
  }
  .slick-slider.slider-wrap.slick-initialized {
    width: unset;
  }
  .our-customer {
    width: 160px;
  }

  .our-customer h6 {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #00000090;
  }

  .our-customer h4 {
    font-size: 13px;
  }
  .our-customer {
    height: 100%;
    background: #fff;
    position: unset;
    z-index: 99;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    padding-left: 0;
    text-align: center;
    width: 100%;
    justify-content: center;
    margin-bottom: 30px;
  }
}
