.our-process-wrap {
  display: flex;
  align-items: center;
  gap: 4vw;
  padding: 50px 0;
}

.our-process-left {
  width: 60%;
}

.our-process-left h6 {
  letter-spacing: 3px;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.our-process-left h2 {
  margin-bottom: 15px;
  font-size: 36px;
}

.our-process-left ul {
  padding-left: 20px;
}

.our-process-left ul li {
  line-height: 26px;
  font-size: 14px;
  color: #232536;
}

.our-process-right {
  width: 35%;
}

.our-process-right img {
  width: 100%;
}

.call-to-action-area {
  background: #583bab;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6% 5%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.call-to-action-left h2 {
  color: #fff;
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
}

.call-to-action-main-area {
  padding: 50px 0;
}

.call-hero-button-area a {
  margin-right: 15px;
}
.call-hero-button-area a:last-child {
  margin-right: 0px;
}
@media only screen and (max-width: 992px) {
  .call-hero-button-area a {
    margin-right: 10px;
  }
  .our-process-wrap {
    display: block;
  }
  .our-process-left {
    width: 100%;
    margin-bottom: 50px;
  }
  .our-process-right {
    width: 100%;
  }
  .hero-inner-left {
    width: 50%;
  }
  .hero-inner-right {
    width: 45%;
  }
}

@media only screen and (max-width: 770px) {
  .call-hero-button-area a {
    margin-right: 10px;
  }
  .our-process-wrap {
    display: block;
  }
  .our-process-left {
    width: 100%;
    margin-bottom: 50px;
  }
  .our-process-right {
    width: 100%;
  }
  .hero-inner-left {
    width: 100%;
  }
  .hero-inner-right {
    width: 100%;
  }
  .call-to-action-area {
    display: block;
    text-align: center;
  }
  .call-to-action-input-wrap {
    /* width: 300px; */
    margin: 20px auto;
  }
}
.hero-inner-area.shomriddhi-hero {
  background: #ec7323;
}

.call-to-action-area.shomriddhi-hero {
  background: #ec7323;
}

.hero-inner-area.shomriddhi-hero h2 span {
  color: #1b3f73;
}

.hero-inner-area.ilsx-hero {
  background: #0052cc;
}

.call-to-action-area.ilsx-hero {
  background: #0052cc;
}

.hero-inner-area.salesx-hero {
  background: #de1f26;
}

.call-to-action-area.salesx-hero {
  background: #de1f26;
}

.hero-inner-area.salesx-hero h2 span {
  color: #1b3f73;
}

.call-to-action-input-wrap {
  display: block;
  align-items: center;
  /* background: #fff; */
  outline: none;
  height: 40px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.call-to-action-right input {
  border: none;
  padding: 0px 10px;
  color: #1b3f73;
  height: 50px;
  width: 380px;
  background: #fff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.call-to-action-right input:focus {
  outline: none;
}

.call-to-action-right input::placeholder {
  color: #1b3f73;
  font-size: 16px;
}

.call-to-action-input-wrap button {
  border: none;
  padding: 0 30px;
  color: #1b3f73;
  background: #fff;
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
  height: 50px;
  font-weight: 700;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.retailx-hero {
  background: #7020f2;
}

@media only screen and (max-width: 992px) {
  .call-to-action-right input {
    width: 210px;
    margin-bottom: 15px;
  }
  .call-to-action-left h2 {
    color: #fff;
    font-weight: 600;
    font-size: 30px;
    line-height: 44px;
  }
}

@media only screen and (max-width: 770px) {
  .call-to-action-input-wrap button {
    margin-left: 5px;
  }

  .call-to-action-area {
    padding: 7% 0;
  }

  .call-to-action-right input {
    width: 237px;
    display: block;
    margin: 0 auto;
  }

  .call-to-action-input-wrap button {
    margin-left: 0;
    margin-top: 30px;
    height: unset;
    padding: 10px 30px;
  }

  .call-to-action-area {
    padding: 30% 0;
    padding-top: 10%;
  }
}

@media only screen and (max-width: 576px) {
  .call-to-action-input-wrap button {
    margin-left: 5px;
  }

  .call-to-action-area {
    padding: 7% 0;
  }

  .call-to-action-right input {
    width: 237px;
  }

  .call-to-action-input-wrap button {
    margin-left: 0;
    margin-top: 30px;
    height: unset;
    padding: 10px 30px;
  }

  .call-to-action-area {
    padding: 30% 0;
    padding-top: 10%;
  }
}
