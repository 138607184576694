* {
  margin: 0;
}

body {
  font-family: "Raleway", sans-serif;
}

p {
  color: #232536;
}

.text-center {
  text-align: center;
}

.App {
  overflow: hidden;
}

.slick-track {
  display: flex;
  align-items: center;
}

.call-hero-button-area a:nth-child(2) {
  background: unset;
  border: 1px solid #fff;
  color: #fff;
  margin-right: 0px;
}

.navbar__link.header-login-btn {
  color: #fff;
  background: #583bab;
  border-radius: 6px;
  padding: 4px 25px;
  border: 2px solid transparent;
  transition: 0.3s;
  margin-left: 10px;
}

a.navbar__link.header-login-btn:hover {
  background: #fff;
  border: 2px solid #1b3f73;
  color: #1b3f73;
}

a.navbar__link.header-registration-btn {
  border: 2px solid #1b3f73;
  padding: 6px 25px;
  margin-left: 12px;
  display: inline-block;
  border-radius: 6px;
}

a.navbar__link.header-registration-btn:hover {
  background: #1b3f73;
  color: #fff;
  transition: 0.5s;
}

.App.home-page-main-bg {
  background: #fafcff;
}
.teacher-single-item p {
  font-size: 18px;
}

/* ============================================================================ */
.business-clases-single-item-wrap {
  height: 350px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px #dddddd80;
  transition: 0.5s;
  position: relative;
}
.business-clases-single-item-wrap:after {
  background: #00000050;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
}
.business-clases-main-part-top {
  background: #00000075;
  text-align: center;
  padding: 10px 0;
  transition: 0.5s;
  position: relative;
  z-index: 9;
}

.business-clases-main-part-top h3 {
  color: #fff;
  font-size: 20px;
}

.business-clases-main-part-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #fff;
  padding: 10px 15px;
  transition: 0.5s;
  z-index: 9;
}

.business-clases-main-part-bottom-inner {
  display: flex;
  align-items: center;
  gap: 15px;
}
.business-clases-main-part-bottom i {
  font-size: 13px;
}
.business-clases-main-part-bottom h4 {
  font-size: 18px;
  font-weight: 700;
}
.business-clases-main-part-bottom-inner-left > h6 > span {
  font-size: 13px;
  font-weight: 300;
  font-style: italic;
  margin-right: 5px;
}
.business-clases-main-part-top i {
  color: #fff;
  font-size: 11px;
  font-weight: 400;
  display: block;
}
/* .business-clases-single-item-wrap:hover {
  transition: 5s;
  transform: scale(1.1);
} */

/* ===================  */

.business-clases-single-item-wrap:hover .business-clases-main-part-top {
  transform: scale(0);
  transform-origin: top;
  transition: 0.5s;
}
.business-clases-single-item-wrap:hover .business-clases-main-part-bottom {
  transform: scale(0);
  transform-origin: bottom;
  transition: 0.5s;
}

.business-cases-service-area > * > .col-lg-4 {
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 25px;
}

.business-clases-hover-part {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #00000080;
  top: 0;
  z-index: 99;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 25px;
  transform: scale(0);
  transform-origin: center;
  transition: 0.5s;
  transition-delay: 0.1s;
}
.business-clases-single-item-wrap:hover .business-clases-hover-part {
  transform: scale(1);
  transition: 0.5s;
  transition-delay: 0.1s;
}
.business-clases-hover-part > * > p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 20px;
}
.business-clases-hover-part-bottom-link {
  position: absolute;
  width: 100%;
  background: #f7b941;
  left: 0;
  bottom: 0;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.business-clases-hover-part-bottom-link > a {
  text-decoration: none;
  color: #fff;
  display: flex;
  gap: 10px;
  font-weight: 500;
  font-size: 24px;
}
.business-cases-bottom-button-area {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.business-cases-bottom-button-area a {
  display: inline-block;
  color: #f7b941;
  text-decoration: none;
  padding: 10px 30px;
  border-radius: 10px;
  border: 2px solid #f7b941;
  transition: 0.5s;
}
.business-cases-bottom-button-area.-service-area-page {
  justify-content: left;
}

.business-cases-bottom-button-area a:hover {
  background: #f7b941;
  color: #fff;
  transition: 0.5s;
}
/* ============================================================================ */
.footer-single-item-content.insights-image > a > img {
  height: 55px;
}
